import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
const resources = {
  en: {
    translation: {
      "Profile": "Profile",
      "Home": "Home",
      "Username": "Username",
      "Checklists": "Checklists",
      "Password": "Password",
      "Sign In": "Sign In",
      "Email": "Email",
      "Code": "Code",
      "Name": "Name",
      "Description": "Description",
      "Revision Number": "Revision Number",
      "Revision Date": "Revision Date",
      "Schedule Checklists": "Schedule Checklists",
      "Quality Checks": "Quality Checks",
      "Scheduled Date From:": "Scheduled Date From:",
      "Scheduled Date Until:": "Scheduled Date Until:",
      "Active": "Active",
      "Finished": "Finished",
      "Expired": "Expired",
      "Scheduled": "Scheduled",
      "Load Data": "Load Data",
      "Checklist Name": "Checklist Name",
      "Scheduled On": "Scheduled On",
      "State": "State",
      "Inspector Name": "Inspector Name",
      "Quality Check Details": "Quality Check Details",
      "Check Started:": "Check Started:",
      "Check Ended:": "Check Ended:",
      "Remarks:": "Remarks:",
      "Number of Questions:": "Number of Questions:",
      "Number of Questions Answered:": "Number of Questions Answered:",
      "Checklist": "Checklist",
      "Questions": "Questions",
      "Question": "Question",
      "Answers": "Answers",
      "Answer": "Answer",
      "Date": "Date",
      "Start Date": "Start Date",
      "Repeat": "Repeat",
      "End Date": "End Date",
      "Daily": "Daily",
      "Weekly": "Weekly",
      "Monthly": "Monthly",
      "Repeat On": "Repeat On",
      "Repeat Frequency": "Repeat Frequency",
      "WeekDay": "WeekDay",
      "Repeat On (Week)": "Repeat On (Week)",
      "Repeat On (Day)": "Repeat On (Day)",
      "Repeat On Day": "Repeat On Day",
      "Repeat On (Date)": "Repeat On (Date)",
      "Repeat On (Month)": "Repeat On (Month)",
      "Monday": "Monday",
      "Tuesday": "Tuesday",
      "Wednesday": "Wednesday",
      "Thursday": "Thursday",
      "Friday": "Friday",
      "Saturday": "Saturday",
      "Sunday": "Sunday",
      "January": "January",
      "February": "February",
      "March": "March",
      "April": "April",
      "May": "May",
      "June": "June",
      "July": "July",
      "August": "August",
      "September": "September",
      "October": "October",
      "November": "November",
      "December": "December",
      "First": "First",
      "Second": "Second",
      "Third": "Third",
      "Fourth": "Fourth",
      "Last": "Last",
      "Schedule & Inspector": "Schedule & Inspector",
      "Check Timing": "Check Timing",
      "Checklist Details": "Checklist Details",
      "Questions and Answers": "Questions and Answers",
      "Yes": "Yes",
      "No": "No",
      "Checklists Distribution for Today": "Checklists Distribution for Today",
      "Checklists Summary": "Checklists Summary",
      "Not Assigned": "Not Assigned",
      "Not Started": "Not Started",
      "Ongoing": "Ongoing",
      "No answer provided": "No answer provided",
      "Username is required": "Username is required",
      "Password is required": "Password is required",
      "First Name": "First Name",
      "Last Name": "Last Name",
      "Display Name": "Display Name",
      "Notes": "Notes",
      "Reports": "Reports",
      "About Care4iT": "About Care4iT",
      "Care4Check, Care4Commerce, and Care4Fresh are products of ": "Care4Check, Care4Commerce, and Care4Fresh are products of ",
      "or": "or",
      "Copyright © 2007-": "Copyright © 2007-",
      " Care4iT b.v. All rights reserved.": " Care4iT b.v. All rights reserved.",
      "Warning:": "Warning:",
      "Logout": "Logout",
      "Checklist scheduled successfully!": "Checklist scheduled successfully!",
      "Error scheduling checklist:": "Error scheduling checklist:",
      "Error scheduling checklist. Please try again.": "Error scheduling checklist. Please try again.",
      "Care4Check is protected by copyright. Unauthorized reproduction or distribution of Care4Check, or any part of it, is strictly prohibited and subject to legal penalties, and will be pursued with all available legal means.": "Care4Check is protected by copyright. Unauthorized reproduction or distribution of Care4Check, or any part of it, is strictly prohibited and subject to legal penalties, and will be pursued with all available legal means.",
      "Checklist schedule deleted successfully!": "Checklist schedule deleted successfully!",
      "Error deleting schedule. Please try again.": "Error deleting schedule. Please try again."
    }
  },
  nl: {
    translation: {
      "Profile": "Profiel",
      "Logout": "Afmelden",
      "Account": "Gebruikersaccount",
      "Active": "Actief",
      "Active checklists": "Actieve controlelijsten",
      "Username": "Gebruikersnaam",
      "Checklists": "Controlelijsten",
      "Password": "Wachtwoord",
      "Sign In": "Inloggen",
      "Answer": "Antwoord",
      "Answers": "Antwoorden",
      "April": "April",
      "August": "Augustus",
      "Capture new": "Nieuwe vastleggen",
      "Check Ended": "Controle beëindigd",
      "Check Started": "Controle begonnen",
      "Check Timing": "Controleer de timing",
      "Checklist": "Controlelijst",
      "Checklist Details": "Controlelijst details",
      "Checklist Name": "Naam van de controlelijst",
      "Checklist remarks": "Controlelijst opmerkingen",
      "Clear": "Verwijder",
      "Code": "Code",
      "Completed": "Voltooid",
      "Daily": "Dagelijks",
      "Date": "Datum",
      "December": "December",
      "Description": "Beschrijving",
      "End Date": "Einddatum",
      "Expired": "Verlopen",
      "February": "Februari",
      "Finished": "Beëindigd",
      "Finish inspection": "Beëindig de controle",
      "First": "Eerste",
      "Fourth": "Vierde",
      "Friday": "Vrijdag",
      "From": "Van",
      "Hi": "Hallo",
      "Home": "Start",
      "Initials or signature": "Initialen of handtekening",
      "Inspector Name": "Naam van de inspecteur",
      "January": "Januari",
      "July": "Juli",
      "June": "Juni",
      "Last": "Laatste",
      "Load Data": "Gegevens laden",
      "March": "Maart",
      "May": "Mei",
      "Monday": "Maandag",
      "Monthly": "Maandelijks",
      "Name": "Naam",
      "No": "Nee",
      "November": "November",
      "Number of Questions": "Aantal vragen",
      "Number of Questions Answered": "Aantal beantwoorde vragen",
      "October": "Oktober",
      "Open question anyway": "Open vraag",
      "Please start inspection before providing answers": "Start alstublieft eerst de controle",
      "Progress": "Voortgang",
      "Quality Check Details": "Details van de kwaliteitscontrole",
      "Quality Checks": "Kwaliteitscontroles",
      "Question": "Vraag",
      "Questions": "Vragen",
      "Questions and Answers": "Vragen en antwoorden",
      "Remarks": "Opmerkingen",
      "Repeat": "Herhalen",
      "Repeat Frequency": "Herhalingsfrequentie",
      "Repeat On": "Herhaal op",
      "Repeat On Date": "Herhaal op datum",
      "Repeat On Day": "Herhalen op dag",
      "Repeat On Month": "Herhalen in maand",
      "Repeat On Week": "Herhaal op week",
      "Revision Date": "Revisiedatum",
      "Revision Number": "Revisienummer",
      "Save progress": "Voortgang opslaan",
      "Saturday": "Zaterdag",
      "Schedule & Inspector": "Schema & Inspecteur",
      "Schedule Checklists": "Controlelijsten plannen",
      "Scheduled": "Gepland",
      "Scheduled checklists": "Geplande controlelijsten",
      "Scheduled Date From": "Geplande datum vanaf",
      "Scheduled Date Until": "Geplande datum tot",
      "Scheduled On": "Gepland op",
      "Second": "Tweede",
      "September": "September",
      "Start Date": "Begindatum",
      "Start inspection": "Begin met controle",
      "State": "Staat",
      "Sunday": "Zondag",
      "Third": "Derde",
      "This checklist state is": "De controlelijst status is",
      "Thursday": "Donderdag",
      "Tuesday": "Dinsdag",
      "Uncategorized": "Niet gecategoriseerd",
      "Upload new": "Nieuwe uploaden",
      "Until": "Tot",
      "Wednesday": "Woensdag",
      "WeekDay": "Weekdag",
      "Weekly": "Wekelijks",
      "What do you want to check today?": "Wat wil je vandaag controleren?",
      "Yes": "Ja",
      "paragraph": "react-i18next es un poderoso marco de internacionalización",
      "First Name": "Voornaam",
      "Last Name": "Achternaam",
      "Email": "E-mail",
      "Display Name": "Weergavenaam",
      "Notes": "Notities",
      "Not Assigned": "Niet Toegewezen",
      "Not Started": "Niet Gestart",
      "Ongoing": "Lopend",
      "No answer provided": "Geen antwoord gegeven",
      "Username is required": "Gebruikersnaam is verplicht",
      "Password is required": "Wachtwoord is verplicht",
      "Reports": "Rapporten",
      "About Care4iT": "Over Care4iT",
      "Care4Check, Care4Commerce, and Care4Fresh are products of ": "Care4Check, Care4Commerce en Care4Fresh zijn producten van ",
      "or": "of",
      "Copyright © 2007-": "Copyright © 2007-",
      " Care4iT b.v. All rights reserved.": " Care4iT b.v. Alle rechten voorbehouden.",
      "Warning:": "Waarschuwing:",
      "Care4Check is protected by copyright. Unauthorized reproduction or distribution of Care4Check, or any part of it, is strictly prohibited and subject to legal penalties, and will be pursued with all available legal means.": "Care4Check is auteursrechtelijk beschermd. Onrechtmatige verveelvoudiging of distributie van Care4Check, of een gedeelte ervan is strikt verboden en strafbaar, en zal met alle beschikbare juridische middelen worden bestreden.",
      "Copyright": "Auteursrecht",
      "Checklist scheduled successfully!": "Checklist succesvol ingepland!",
      "Error scheduling checklist:": "Fout bij het inplannen van de checklist:",
      "Error scheduling checklist. Please try again.": "Fout bij het inplannen van de checklist. Probeer het opnieuw.",
      "Checklist schedule deleted successfully!": "Checklistschema succesvol verwijderd!",
      "Error deleting schedule. Please try again.": "Fout bij het verwijderen van het schema. Probeer het opnieuw."
    }
  }
};

const languageDetector = new LanguageDetector(null, {
  order: ['localStorage', 'navigator', 'htmlTag'],
  caches: ['localStorage'],
  lookupLocalStorage: 'i18nextLng',
});

i18n
  .use(languageDetector)
  .use(initReactI18next)
  .init({
    resources,
    fallbackLng: 'nl',
    detection: {
      order: ['localStorage', 'navigator', 'htmlTag'],
      caches: ['localStorage'],
    },
    debug: true,
  });

export default i18n;