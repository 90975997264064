import HttpClient from "./httpClient";
import { PagedList } from "./types/pagedList";
import { QualityCheck } from "./types/qualityCheck";
import { QualityCheckDetails } from "./types/qualityCheckDetails";
import { QualityChecksSummary } from "./types/qualityChecksSummary";

const baseUrl = '/api/qualityChecks';
const getUrl = (searchParams: URLSearchParams): string => (`${baseUrl}?${searchParams}`);

export default class QualityChecksApi {


    static async getQualityCheckSummary(): Promise<QualityChecksSummary[]>{

        var response = await HttpClient.get(`${baseUrl}/summary`);
        return await response.json();
    }

    static async getQualityCheck(id:any ):Promise<QualityCheckDetails> {
        const queryParams = id;

        var response = await HttpClient.get(`${baseUrl}/${queryParams}`);
        return await response.json();
    }

    static async getReports(
        scheduledDateFrom: string,
        scheduledDateUntil: string,
        stateActive: boolean,
        stateFinished: boolean,
        stateExpired: boolean,
        stateScheduled: boolean,
        skip: number,
        take: number):Promise<PagedList<QualityCheck>> {

        const queryParams = new URLSearchParams({
            scheduledDateFrom,
            scheduledDateUntil,
            stateActive: stateActive.toString(),
            stateFinished: stateFinished.toString(),
            stateExpired: stateExpired.toString(),
            stateScheduled: stateScheduled.toString(),
            skip: skip.toString(),
            take: take.toString(),
        });

        var response = await HttpClient.get(getUrl(queryParams));
        return await response.json();
    }
}