import { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  DataGrid,
  Column,
  Paging,
  Sorting,
  Pager,
} from "devextreme-react/data-grid";
import "./reports.css";
import { t } from "i18next";
import { Button, LoadIndicator, LoadPanel } from "devextreme-react";
import QualityChecksApi from "../../api/qualityChecksApi";
import { QualityCheck } from "../../api/types/qualityCheck";

export default function Reports() {
  const today = new Date().toISOString().split("T")[0];

  const getTwoWeeksAgoDate = (): string => {
    const today = new Date();
    const twoWeeksAgo = new Date(today.getTime() - 14 * 24 * 60 * 60 * 1000);
    return twoWeeksAgo.toISOString().split("T")[0];
  };

  const navigate = useNavigate();
  const [scheduledDateFrom, setScheduledDateFrom] = useState<string>(getTwoWeeksAgoDate());
  const [scheduledDateUntil, setScheduledDateUntil] = useState<string>(today);
  const [stateActive, setStateActive] = useState<boolean>(true);
  const [stateFinished, setStateFinished] = useState<boolean>(true);
  const [stateExpired, setStateExpired] = useState<boolean>(false);
  const [stateScheduled, setStateScheduled] = useState<boolean>(true);
  const [qualityChecks, setQualityChecks] = useState<QualityCheck[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  const [page, setPage] = useState<number>(1);

  const [totalCount, setTotalCount] = useState(0);
  const [showInfo, setShowInfo] = useState(true);
  const pageSize = 10;
  const dataGridRef = useRef(null);

  const handleRowClick = (e: { data: { idQualityCheck: any } }) => {
    navigate(`/reports/details/${e.data.idQualityCheck}`);
  };

  const loadData = async (pageIndex: number, pageSize: number) => {
    setLoading(true);
    try {
      const data = await QualityChecksApi.getReports(
        scheduledDateFrom,
        scheduledDateUntil,
        stateActive,
        stateFinished,
        stateExpired,
        stateScheduled,
        pageIndex,
        pageSize * 2
      );

      setTotalCount(data.totalCount);
      setQualityChecks(data.items);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  const getStatusLabel = (status: number): string => {
    switch (status) {
      case 0:
        return t("Scheduled");
      case 1:
        return t("Active");
      case 2:
        return t("Finished");
      case 3:
        return t("Expired");
      default:
        return t("Unknown");
    }
  };

  const handleOptionChanged = (e: any) => {
    if (e.fullName === "paging.pageIndex") {
      setPage(e.value + 1);
      if(totalCount % e.value + 1 < pageSize){
        loadData(e.value + 1, pageSize);
      }

    }
  };

  return (
    <div className="container">
      <h2 className="form-group">{t("Quality Checks")}</h2>
      <div className="form-group">
        <label className="form-label">{t("Scheduled Date From:")}</label>
        <input
          className="form-input"
          type="date"
          value={scheduledDateFrom}
          onChange={(e) => setScheduledDateFrom(e.target.value)}
        />
      </div>
      <div className="form-group">
        <label className="form-label">{t("Scheduled Date Until:")}</label>
        <input
          className="form-input"
          type="date"
          value={scheduledDateUntil}
          onChange={(e) => setScheduledDateUntil(e.target.value)}
        />
      </div>
      <div className="form-group">
        <label className="form-label">
          <input
            className="form-checkbox"
            type="checkbox"
            checked={stateActive}
            onChange={(e) => setStateActive(e.target.checked)}
          />
          {t("Active")}
        </label>
        <label className="form-label">
          <input
            className="form-checkbox"
            type="checkbox"
            checked={stateFinished}
            onChange={(e) => setStateFinished(e.target.checked)}
          />
          {t("Finished")}
        </label>
        <label className="form-label">
          <input
            className="form-checkbox"
            type="checkbox"
            checked={stateExpired}
            onChange={(e) => setStateExpired(e.target.checked)}
          />
          {t("Expired")}
        </label>
        <label className="form-label">
          <input
            className="form-checkbox"
            type="checkbox"
            checked={stateScheduled}
            onChange={(e) => setStateScheduled(e.target.checked)}
          />
          {t("Scheduled")}
        </label>
      </div>
      <div className="button-container">
        <Button onClick={() => loadData(1, 10)} stylingMode="contained" type="default">
          <LoadIndicator className="dx-button-indicator" visible={loading} />
          <span className="dx-button-text">{t("Load Data")}</span>
        </Button>
      </div>

      <div className="data-grid-container">
        <LoadPanel visible={loading} />
        <DataGrid
          ref={dataGridRef}
          onOptionChanged={handleOptionChanged}
          remoteOperations={{ paging: true }}
          dataSource={qualityChecks}
          showBorders={true}
          rowAlternationEnabled={true}
          onRowClick={handleRowClick}
        >
          <Paging enabled={true} defaultPageSize={10} />
          <Sorting mode="none" />
          <Column dataField="idQualityCheck" caption="ID" width={70} />
          <Column
            dataField="checklist.name"
            caption={t("Checklist Name")}
            width={300}
          />
          <Column
            width={170}
            dataField="scheduledOn"
            caption={t("Scheduled On")}
            dataType="date"
          />
          <Column
            dataField="state"
            caption={t("State")}
            width={170}
            customizeText={({ value }) => getStatusLabel(value)}
          />
          <Column dataField="inspectorName" caption={t("Inspector Name")} />
          <Pager
            visible={true}
            showInfo={showInfo}
            infoText={`Total Items: ${totalCount}`}
            showNavigationButtons={true}
          />
        </DataGrid>
      </div>
    </div>
  );
}
