import { useMemo, useCallback, useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import ContextMenu, { Position } from 'devextreme-react/context-menu';
import List from 'devextreme-react/list';
import { useAuth } from '../../contexts/auth';
import { useTranslation } from 'react-i18next'; 
import './UserPanel.scss';
import type { UserPanelProps } from '../../types';
import { BACKENDURL } from '../../api/constants';

export default function UserPanel({ menuMode }: UserPanelProps) {
  const [userprofile, setUserProfile] = useState<IUserProfile | undefined>(undefined);
  const [profilePicture, setProfilePicture] = useState<string>("");
  const { signOut } = useAuth();
  const navigate = useNavigate();
  const { t } = useTranslation(); 

  interface IUserProfile {
    profilePicture: Uint8Array | null;
    displayName: string | null;
  }

  const navigateToProfile = useCallback(() => {
    navigate("/profile");
  }, [navigate]);

  const menuItems = useMemo(() => ([
    {
      text: t('Profile'), 
      icon: 'user',
      onClick: navigateToProfile
    },
    {
      text: t('Logout'), 
      icon: 'runner',
      onClick: signOut
    }
  ]), [navigateToProfile, signOut, t]); 

  const getImageSrc = (base64String: Uint8Array): string => {
    return `data:image/jpeg;base64,${base64String}`;
  };

  useEffect(() => {
    const fetchEmployeeData = async () => {
      try {
        const response = await fetch(`${BACKENDURL}/api/user`, {
          method: "GET",
          credentials: "include",
          headers: {
            "Content-Type": "application/json",
          },
        });
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const data: IUserProfile = await response.json();
        setUserProfile(data);
        if (data.profilePicture) {
          setProfilePicture(getImageSrc(data.profilePicture));
        }
      } catch (error) {
        console.error("Error fetching employee data:", error);
      }
    };
    fetchEmployeeData();
  }, []);

  return (
    <div className={'user-panel'} >
      <div className={'user-info'}>
        <div className={'image-container'}>
          {profilePicture ? (
            <img
              src={profilePicture}
              alt={t("Profile")} 
              style={{ width: "30px", height: "30px" }}
            />
          ) : (
            <p>{t('No profile picture available')}</p> 
          )}
        </div>
        <div className={'user-name'}>{userprofile?.displayName}</div>
      </div>

      {menuMode === 'context' && (
        <ContextMenu
          items={menuItems}
          target={'.user-button'}
          showEvent={'dxclick'}
          width={250}
          cssClass={'user-menu'}
        >
          <Position my={{ x: 'center', y: 'top' }} at={{ x: 'center', y: 'bottom' }} />
        </ContextMenu>
      )}
      {menuMode === 'list' && (
        <List className={'dx-toolbar-menu-action'} items={menuItems} />
      )}
    </div>
  );
}
