import HttpClient from "./httpClient";
import { ChecklistQuestion } from "./types/checklistQuestion";
import { QuestionRequest } from "./types/questionRequest";

const baseUrl = '/api/questions';
const getByIdUrl = (id: number): string => (`${baseUrl}/${id}`);

export default class ChecklistQuestionsApi {

    static async addQuestion(
        questionsRequests: QuestionRequest[]
    ): Promise<{ succeeded: boolean }> {
        var response = await HttpClient.post(baseUrl, {
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(questionsRequests),
        });
        return {
            succeeded: response.ok
        }
    }

    static async editQuestion(
        id: number,
        questionsRequests: QuestionRequest
    ): Promise<{ succeeded: boolean }> {
        var response = await HttpClient.put(getByIdUrl(id), {
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(questionsRequests),
        });
        return {
            succeeded: response.ok
        }
    }

    static async deleteQuestion(
        id: number
    ): Promise<{ succeeded: boolean }> {
        var response = await HttpClient.delete(getByIdUrl(id));
        return {
            succeeded: response.ok
        }
    }

    static async upsertQuestions(
        checklistId: number,
        questionsRequests: QuestionRequest[]
    ): Promise<{ success: boolean; questions?: ChecklistQuestion[]; message?: string }> {
        try {
            const response = await HttpClient.put(`${baseUrl}/upsert/${checklistId}`, {
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(questionsRequests),
            });

            const result = await response.json();
            return response.ok
                ? { success: true, questions: result.updatedQuestions }
                : { success: false, message: result.message || "Failed to upsert questions." };
        } catch (error) {
            console.error("Error in upsertQuestions:", error);
            return { success: false, message: "An error occurred while upserting questions." };
        }
    }
}
