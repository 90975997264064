import React from "react";

interface CustomTooltipProps {
  appointmentData: { id: number; text: string };
  handleDelete: ( id: number ) => void;
}

const CustomTooltipRender: React.FC<CustomTooltipProps> = ({
  appointmentData,
  handleDelete
}) => {
  return (
    <div
      id="appointmentMessage"
      className="custom-tooltip"
      onDoubleClick={(e) => e.stopPropagation()}
      onClick={(e) => e.stopPropagation()}
    >
      <span className="tooltip-text">{appointmentData.text}</span>
      <i
        style={{ fontSize: "20px" }}
        className="dx-icon-trash"
        onClick={(e) => {
          e.stopPropagation();
          handleDelete(appointmentData.id);
        }}
      ></i>
    </div>
  );
};

export default CustomTooltipRender;
