import { TabPanel } from "devextreme-react";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { Item } from "devextreme-react/cjs/tab-panel";
import ScheduleChecklists from "../schedulechecklists/schedulechecklists";
import QualityChecksList from "./QualityChecksList";

export default function QualityCheckTabs() {
    const location = useLocation()
    const navigate = useNavigate()
    const [selectedTab, setSelectedTab] = useState(0)

    const tabs = [
        { id: 0, view: "list", title: "List", component: <QualityChecksList /> },
        { id: 1, view: "calendar", title: "Calendar", component: <ScheduleChecklists schedulerClassName="data-grid" occupiedHeight={230} /> }
    ]

    const getViewFromUrl = () => {
        const queryParams = new URLSearchParams(location.search)
        return queryParams.get("viewType") ?? "list"
    }

    useEffect(() => {
        const view = getViewFromUrl()
        const tab = tabs.find(e => e.view === view)?.id ?? 0

        setSelectedTab(tab)
    }, [])

    const handleTabChange = (e: any) => {
        const index: number = e.component.option("selectedIndex")
        if (index === selectedTab)
            return

        const view = tabs[index].view ?? "list"

        navigate({ search: `?viewType=${view}`})
        setSelectedTab(index);
    };

    return (
        <div>
            <TabPanel
                selectedIndex={selectedTab}
                onSelectionChanged={handleTabChange}
                deferRendering>
                    {tabs.map(e => <Item title={e.title} />)}
            </TabPanel>
            {tabs[selectedTab].component}
      </div>
    )
}