export enum MonthlyFrequencyDays {
  Day1 = 1,
  Day2 = 2,
  Day3 = 4,
  Day4 = 8,
  Day5 = 0x10,
  Day6 = 0x20,
  Day7 = 0x40,
  Day8 = 0x80,
  Day9 = 0x100,
  Day10 = 0x200,
  Day11 = 0x400,
  Day12 = 0x800,
  Day13 = 0x1000,
  Day14 = 0x2000,
  Day15 = 0x4000,
  Day16 = 0x8000,
  Day17 = 0x10000,
  Day18 = 0x20000,
  Day19 = 0x40000,
  Day20 = 0x80000,
  Day21 = 0x100000,
  Day22 = 0x200000,
  Day23 = 0x400000,
  Day24 = 0x800000,
  Day25 = 0x1000000,
  Day26 = 0x2000000,
  Day27 = 0x4000000,
  Day28 = 0x8000000,
  Day29 = 0x10000000,
  Day30 = 0x20000000,
  Day31 = 0x40000000,
  Last = 0x80000000,
}
