import React, {
  useState,
  useEffect,
  createContext,
  useContext,
  useCallback,
} from "react";
import { getUser, signIn as sendSignInRequest } from "../api/auth";
import type { User, AuthContextType } from "../types";
import { BACKENDURL } from "../api/constants";

function AuthProvider(props: React.PropsWithChildren<unknown>) {
  const [user, setUser] = useState<User>();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    (async function () {
      const result = await getUser();
      if (result.isOk) {
        setUser(result.data);
      }

      setLoading(false);
    })();
  }, []);

  const signIn = useCallback(async (username: string, password: string) => {
    const result = await sendSignInRequest(username, password);
    if (result.isOk) {
      setUser(result.data);
    }
    return result;
  }, []);
  
  const signOut = useCallback(() => {
    fetch(`${BACKENDURL}/api/logout`, {
      method: "POST", 
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include", 
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Failed to log out");
        }
      })
      .catch((error) => {
        console.error("Logout error:", error);
      });
      setUser(undefined);
  }, []);

  return (
    <AuthContext.Provider
      value={{ user, signIn, signOut, loading }}
      {...props}
    />
  );
}

const AuthContext = createContext<AuthContextType>({
  loading: false,
} as AuthContextType);
const useAuth = () => useContext(AuthContext);

export { AuthProvider, useAuth };
