import React, { useEffect, useState } from "react";
import "./profile.scss";
import { useTranslation } from "react-i18next";
import ProfileApi from "../../api/profileApi";
import { LoadPanel } from "devextreme-react";
import { UserProfile } from "../../api/types/userProfile";
import  secureLocalStorage  from  "react-secure-storage";

const Profile: React.FC = () => {
  const { t, i18n } = useTranslation();
  const changeLanguage = (lng: string | undefined) => {
    i18n.changeLanguage(lng);
   };

  const [notes, setNotes] = useState<string>("");
  const [employee, setEmployee] = useState<UserProfile | undefined>(undefined);
  const [profilePicture, setProfilePicture] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);

  const getImageSrc = (base64String: Uint8Array): string => {
    return `data:image/jpeg;base64,${base64String}`;
  };

  useEffect(() => {

    const fetchEmployeeData = async () => {
      try {
        setLoading(true);

        var data: UserProfile = await ProfileApi.getUserProfile();
        secureLocalStorage.setItem("UserId", data.idUser)
        setEmployee(data);
        setNotes(data.notes || "");

        if (data.profilePicture) {
          setProfilePicture(getImageSrc(data.profilePicture));
        }
      } catch (error) {
        console.error("Error fetching employee data:", error);
      }
      finally {
        setLoading(false);
      }
    };
    fetchEmployeeData();
  }, [i18n]);

  if (!employee) {
    return  <LoadPanel visible={loading} />
  }

  return (
    <div className="profile-container">
      <h2>{t("Profile")}</h2>
      <div className="profile-card">
        <div className="profile-container">
          {profilePicture ? (
            <img
              src={profilePicture}
              alt="Profile"
              style={{ width: "200px", height: "250px" }}
            />
          ) : (
            <p>No profile picture available</p>
          )}
          <div className="language-buttons">
            <button onClick={() => changeLanguage("en")}>English</button>
            <button onClick={() => changeLanguage("nl")}>Dutch</button>
          </div>
          <span className="notes">{notes}</span>
        </div>
        <div className="profile-details">
          <div className="form-group">
            <label>{t("Username")}</label>
            <input
              type="text"
              name="userName"
              value={employee.userName || ""}
              className="form-control"
            />
          </div>
          <div className="form-group">
            <label>{t("Email")}</label>
            <input
              type="email"
              name="email"
              value={employee.email || ""}
              className="form-control"
            />
          </div>
          <div className="form-group">
            <label>{t("First Name")}</label>
            <input
              type="text"
              name="firstName"
              value={employee.firstName || ""}
              className="form-control"
            />
          </div>
          <div className="form-group">
            <label>{t("Last Name")}</label>
            <input
              type="text"
              name="lastName"
              value={employee.lastName || ""}
              className="form-control"
            />
          </div>
          <div className="form-group">
            <label>{t("Display Name")}</label>
            <input
              type="text"
              name="displayName"
              value={employee.displayName || ""}
              className="form-control"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Profile;
