import Scheduler, {
  SchedulerTypes,
} from "devextreme-react/scheduler";
import React, { useEffect, useRef, useState } from "react";
import Appointment from "./appointment";
import {
  AppointmentAddedEvent,
  AppointmentDblClickEvent,
} from "devextreme/ui/scheduler_types";
import { DailyFrequencyDays } from "./enum/dailyfrequency";
import "./schedulechecklists";
import { MonthlyFrequencyOn } from "./enum/monthlyfrequencyon";
import { MonthlyFrequencyMonths } from "./enum/monthlyfrequency";
import { WeeklyFrequencyDays } from "./enum/weeklyfrequencydays";
import { MonthlyFrequencyDays } from "./enum/days";
import { t } from "i18next";
import { BACKENDURL } from "../../api/constants";
import { Toast } from 'devextreme-react/toast';
import CustomTooltipRender from "./customtooltiprender";
import { Button } from "devextreme-react";
const currentDate = new Date();
const views: SchedulerTypes.ViewType[] = ["month"];

interface IChecklist {
  idChecklist: number;
  code: string;
  name: string;
  description: string | null;
  revisionNumber: number;
  revisionDate: string; // Using string to represent dates as ISO strings
  state: number;
  idAdministration: number;
  questions: any[] | null; // Specify more precise type if known
  schedules: any[] | null; // Specify more precise type if known
}
interface FormState {
  idChecklist: number | null;
  onceDate: string;
  startDate: string;
  endDate: string;
  enableRepeat: boolean;
  repeatType: ScheduleType;
  repeatEvery: number;
  repeatOnDay: DailyFrequencyDays[];
  repeatOnDays: WeeklyFrequencyDays;
  repeatOnMonths: number[];
  repeatOnWeekdayOnMonths: boolean;
  monthlyFrequencyDay: number[];
  monthlyOnWeekDays: DailyFrequencyDays[];
  monthlyFrequencyOnWeekDay: DailyFrequencyDays[];
}

interface DayItem {
  day: string;
  value: DailyFrequencyDays;
}
interface ValueChangeEventArgs {
  value: DailyFrequencyDays[];
}
enum ScheduleType {
  Inactive = 0,
  Once = 1,
  Daily = 2,
  Weekly = 3,
  Monthly = 4,
}

export default function ScheduleChecklists({
  occupiedHeight = 0,
  schedulerClassName = "",
  addButtonClassName = ""}: {
    occupiedHeight?: number
    schedulerClassName?: string
    addButtonClassName?: string
  }) {
  const [checklists, setChecklists] = useState<IChecklist[]>([]);
  const [toastVisible, setToastVisible] = useState(false);
  const [toastMessage, setToastMessage] = useState('');
  const [toastType, setToastType] = useState<'success' | 'error'>('success');
  const schedulerRef = useRef<Scheduler>(null);

  useEffect(() => {
    const fetchChecklists = async () => {
      try {
        const response = await fetch(
          `${BACKENDURL}/api/checklists?skip=0&take=100`,
          {
            credentials: "include",
            method: "GET",
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        if (!response.ok) {
          throw new Error("Failed to fetch checklists");
        }
        const data = await response.json();
        setChecklists(data);
      } catch (error) {
        console.error("Error fetching checklists:", error);
      }
    };

    fetchChecklists();
  }, []);

  const [formState, setFormState] = useState<FormState>({
    idChecklist: null,
    onceDate: "",
    startDate: "",
    endDate: "",
    enableRepeat: false,
    repeatType: 1,
    repeatEvery: 0,
    repeatOnDay: [],
    repeatOnDays: 1,
    repeatOnMonths: [],
    repeatOnWeekdayOnMonths: false,
    monthlyFrequencyDay: [],
    monthlyOnWeekDays: [],
    monthlyFrequencyOnWeekDay: [],
  });
  const handleValueChange = (field: keyof FormState, value: any) => {
    setFormState((prevState) => ({
      ...prevState,
      [field]: value,
    }));
  };
  const onAppointmentFormOpening = async (
    e: SchedulerTypes.AppointmentFormOpeningEvent
  ): Promise<void> => {
    let initialRepeatState = false;

    e.form.option("items", [
      {
        dataField: "customFieldId",
        editorType: "dxSelectBox",
        editorOptions: {
          onValueChanged: (e: { value: any }) =>
            handleValueChange("idChecklist", e.value),
          dataSource: checklists,
          displayExpr: "name", // Display name in the dropdown
          valueExpr: "idChecklist", // Use idChecklist as the value of items
          searchEnabled: true,
          validationError: {
            message: "Selection of checklist is mandatory", // Custom error message
          },
          showClearButton: true, // Allows users to clear the input if needed
          isValid: true, // Starts with invalid state
        },
        colSpan: 2,
        label: {
          text: t("Checklist"),
        },
        validationRules: [
          { type: "required", message: "Checklist is a required field." },
        ],
      },
      {
        dataField: "onceDate",
        editorType: "dxDateBox",
        editorOptions: {
          onValueChanged: (e: { value: any }) =>
            handleValueChange("onceDate", e.value),
          type: "date",
          value: new Date(),
          displayFormat: "yyyy/MM/dd",
          useMaskBehavior: true,
          dateSerializationFormat: "yyyy-MM-dd",
          validationError: {
            message: "Date is mandatory",
          },
          isValid: true,
          isVisible: false,
        },
        visible: false,
        validationRules: [
          { type: "required", message: "Date is a required field." },
        ],
        label: {
          text: t("Date"),
        },
      },
      {
        dataField: "startDate",
        editorType: "dxDateBox",
        editorOptions: {
          default: new Date(),
          type: "date",
          onValueChanged: (e: { value: any }) =>
            handleValueChange("startDate", e.value),
          displayFormat: "yyyy/MM/dd",
          useMaskBehavior: true,
          dateSerializationFormat: "yyyy-MM-dd",
        },
        label: {
          text: t("Start Date"),
        },
      },
      {
        dataField: "endDate",
        editorType: "dxDateBox",
        editorOptions: {
          type: "date",
          onValueChanged: (e: { value: any }) =>
            handleValueChange("endDate", e.value),
          displayFormat: "yyyy/MM/dd",
          useMaskBehavior: true,
          dateSerializationFormat: "yyyy-MM-dd",
        },
        label: {
          text: t("End Date"),
        },
      },
      {
        dataField: "enableRepeat",
        editorType: "dxSwitch",
        editorOptions: {
          onText: "Enabled",
          offText: "Disabled",
          onValueChanged: function (e: { value: boolean }) {
            updateDateFieldsVisibility(e.value);
            handleValueChange("enableRepeat", e.value);
          },
        },
        label: {
          text: t("Repeat"),
        },
        visible: true,
      },
      {
        dataField: "repeatType",
        editorType: "dxSelectBox",
        editorOptions: {
          dataSource: repeatOptions,
          displayExpr: t("name"),
          valueExpr: "id",
          onValueChanged: function (e: { component?: any; value?: any }): void {
            // Assuming `e.value` will give you the id of the selected option
            const selectedOption = repeatOptions.find(
              (option) => option.id === e.value
            );
            handleValueChange("repeatType", e.value);
            if (selectedOption) {
              updateFormItemsVisibility(selectedOption);
            } else {
              console.error("Selected option is undefined");
            }
          },
          validationError: {
            message: "Repeat type is mandatory",
          },
          showClearButton: true,
          isValid: true,
        },
        validationRules: [
          { type: "required", message: "Repeat type is a required field." },
        ],
        label: {
          text: t("Repeat"),
        },
        visible: true,
      },
      {
        dataField: "repeatOnDay",
        editorType: "dxTagBox", // Change from dxTagBox to dxSelectBox
        editorOptions: {
          onValueChanged: (e: { value: any }) => {
            handleValueChange("repeatOnDay", e.value); // Directly use e.value
          },
          items: [
            { day: t("Sunday"), value: DailyFrequencyDays.Sunday },
            { day: t("Monday"), value: DailyFrequencyDays.Monday },
            { day: t("Tuesday"), value: DailyFrequencyDays.Tuesday },
            { day: t("Wednesday"), value: DailyFrequencyDays.Wednesday },
            { day: t("Thursday"), value: DailyFrequencyDays.Thursday },
            { day: t("Friday"), value: DailyFrequencyDays.Friday },
            { day: t("Saturday"), value: DailyFrequencyDays.Saturday },
          ] as DayItem[],
          displayExpr: "day",
          valueExpr: "value",
          showClearButton: true, // Allows users to clear the selected value
          searchEnabled: false, // Depending on your UI requirements, you can enable or disable searching within the dropdown
          validationError: {
            message: "Week days type is mandatory", // Custom error message
          },
          isValid: true, // Starts with invalid state
        },
        colSpan: 2,
        label: {
          text: t("Repeat On Day"),
        },
        validationRules: [
          { type: "required", message: "Week days is a required field." },
        ],
        visible: false, // Control visibility dynamically as needed
      },
      {
        dataField: "repeatOnDays",
        editorType: "dxSelectBox",
        editorOptions: {
          onValueChanged: (e: ValueChangeEventArgs) =>
            handleValueChange("repeatOnDays", e.value),
          items: [
            { day: t("Sunday"), value: WeeklyFrequencyDays.Sunday },
            { day: t("Monday"), value: WeeklyFrequencyDays.Monday },
            { day: t("Tuesday"), value: WeeklyFrequencyDays.Tuesday },
            { day: t("Wednesday"), value: WeeklyFrequencyDays.Wednesday },
            { day: t("Thursday"), value: WeeklyFrequencyDays.Thursday },
            { day: t("Friday"), value: WeeklyFrequencyDays.Friday },
            { day: t("Saturday"), value: WeeklyFrequencyDays.Saturday },
          ],
          displayExpr: "day",
          valueExpr: "value",
          multiline: false,
        },
        colSpan: 2,
        label: {
          text: t("Repeat On"),
        },
        validationRules: [
          { type: "required", message: "Week days is a required field." },
        ],
        visible: false,
      },
      {
        dataField: "repeatOnMonths",
        editorType: "dxTagBox",
        editorOptions: {
          onValueChanged: (e: { value: any }) =>
            handleValueChange("repeatOnMonths", e.value),
          items: [
            { month: t("January"), value: MonthlyFrequencyMonths.January },
            { month: t("February"), value: MonthlyFrequencyMonths.February },
            { month: t("March"), value: MonthlyFrequencyMonths.March },
            { month: t("April"), value: MonthlyFrequencyMonths.April },
            { month: t("May"), value: MonthlyFrequencyMonths.May },
            { month: t("June"), value: MonthlyFrequencyMonths.June },
            { month: t("July"), value: MonthlyFrequencyMonths.July },
            { month: t("August"), value: MonthlyFrequencyMonths.August },
            { month: t("September"), value: MonthlyFrequencyMonths.September },
            { month: t("October"), value: MonthlyFrequencyMonths.October },
            { month: t("November"), value: MonthlyFrequencyMonths.November },
            { month: t("December"), value: MonthlyFrequencyMonths.December },
          ],
          displayExpr: "month",
          valueExpr: "value",
          showClearButton: true,
        },
        colSpan: 2,
        label: {
          text: t("Repeat On (Month)"),
        },
        validationRules: [
          { type: "required", message: "Week days is a required field." },
        ],
        visible: false,
      },
      {
        dataField: "repeatOnWeekdayOnMonths",
        editorType: "dxRadioGroup",
        editorOptions: {
          onValueChanged: (e: { value: boolean }) => {
            const selectedOption = e.value;
            handleValueChange("repeatOnWeekdayOnMonths", e.value);
            updateMonthlyFrequencyFieldsVisibility(selectedOption);
          },
          items: [
            { label: t("Date"), value: false },
            { label: t("On"), value: true },
          ],
          valueExpr: "value",
          displayExpr: "label",
          layout: "horizontal",
        },
        label: {
          text: t("Repeat Frequency"),
        },
        visible: false,
      },
      {
        dataField: "monthlyFrequencyDay",
        editorType: "dxTagBox",
        editorOptions: {
          onValueChanged: (e: { value: any }) =>
            handleValueChange("monthlyFrequencyDay", e.value), //MonthlyFrequency
          dataSource: [
            { day: "Day1", value: 1 },
            { day: "Day2", value: 2 },
            { day: "Day3", value: 4 },
            { day: "Day4", value: 8 },
            { day: "Day5", value: 16 },
            { day: "Day6", value: 32 },
            { day: "Day7", value: 64 },
            { day: "Day8", value: 128 },
            { day: "Day9", value: 256 },
            { day: "Day10", value: 512 },
            { day: "Day11", value: 1024 },
            { day: "Day12", value: 2048 },
            { day: "Day13", value: 4096 },
            { day: "Day14", value: 8192 },
            { day: "Day15", value: 16384 },
            { day: "Day16", value: 32768 },
            { day: "Day17", value: 65536 },
            { day: "Day18", value: 131072 },
            { day: "Day19", value: 262144 },
            { day: "Day20", value: 524288 },
            { day: "Day21", value: 1048576 },
            { day: "Day22", value: 2097152 },
            { day: "Day23", value: 4194304 },
            { day: "Day24", value: 8388608 },
            { day: "Day25", value: 16777216 },
            { day: "Day26", value: 33554432 },
            { day: "Day27", value: 67108864 },
            { day: "Day28", value: 134217728 },
            { day: "Day29", value: 268435456 },
            { day: "Day30", value: 536870912 },
            { day: "Day31", value: 1073741824 },
            { day: "Last", value: 2147483648 },
          ],
          displayExpr: "day",
          valueExpr: "value",
          showClearButton: true,
          searchEnabled: false,
          validationError: {
            message: "Date is mandatory",
          },
          isValid: true,
        },
        label: {
          text: t("Repeat On (Date)"),
        },
        colSpan: 2,
        visible: false,
        validationRules: [
          { type: "required", message: "Date is a required field." },
        ],
      },
      {
        dataField: "monthlyOnWeekDays",
        editorType: "dxTagBox",
        editorOptions: {
          onValueChanged: (e: ValueChangeEventArgs) =>
            handleValueChange("monthlyOnWeekDays", e.value),
          items: [
            { week: t("First"), value: MonthlyFrequencyOn.First },
            { week: t("Second"), value: MonthlyFrequencyOn.Second },
            { week: t("Third"), value: MonthlyFrequencyOn.Third },
            { week: t("Fourth"), value: MonthlyFrequencyOn.Fourth },
            { week: t("Last"), value: MonthlyFrequencyOn.Last },
          ],
          displayExpr: "week",
          valueExpr: "value",
          multiline: false,
          searchEnabled: false, // Depending on your UI requirements, you can enable or disable searching within the dropdown
          validationError: {
            message: "Week is mandatory", // Custom error message
          },
          isValid: true, // Starts with invalid state
        },
        colSpan: 2,
        label: {
          text: t("Repeat On"),
        },
        visible: false,
        validationRules: [
          { type: "required", message: "Week is a required field." },
        ],
      },
      {
        dataField: "monthlyFrequencyOnWeekDay",
        editorType: "dxTagBox",
        editorOptions: {
          onValueChanged: (e: ValueChangeEventArgs) =>
            handleValueChange("monthlyFrequencyOnWeekDay", e.value),
          items: [
            { day: t("Sunday"), value: DailyFrequencyDays.Sunday },
            { day: t("Monday"), value: DailyFrequencyDays.Monday },
            { day: t("Tuesday"), value: DailyFrequencyDays.Tuesday },
            { day: t("Wednesday"), value: DailyFrequencyDays.Wednesday },
            { day: t("Thursday"), value: DailyFrequencyDays.Thursday },
            { day: t("Friday"), value: DailyFrequencyDays.Friday },
            { day: t("Saturday"), value: DailyFrequencyDays.Saturday },
          ] as DayItem[],
          displayExpr: "day",
          valueExpr: "value",
          multiline: false,
          searchEnabled: false,
          validationError: {
            message: "Week is mandatory",
          },
          isValid: true,
        },
        colSpan: 2,
        label: {
          text: t("Repeat On (Day)"),
        },
        visible: false,
        validationRules: [
          { type: "required", message: "Week day is a required field." },
        ],
      },
    ]);

    updateDateFieldsVisibility(initialRepeatState);

    function updateMonthlyFrequencyFieldsVisibility(
      isWeekdaySelected: boolean
    ): void {
      const form = e.form;

      const items = form.option("items") as Array<any>;

      const monthlyFrequencyDayItem = items.find(
        (item) => item.dataField === "monthlyFrequencyDay"
      );
      const monthlyOnWeekDaysItem = items.find(
        (item) => item.dataField === "monthlyOnWeekDays"
      );
      const monthlyFrequencyOnWeekDayItem = items.find(
        (item) => item.dataField === "monthlyFrequencyOnWeekDay"
      );
      if (!isWeekdaySelected) {
        monthlyFrequencyDayItem.visible = true;
        monthlyOnWeekDaysItem.visible = false;
        monthlyFrequencyOnWeekDayItem.visible = false;
      } else {
        monthlyFrequencyDayItem.visible = false;
        monthlyOnWeekDaysItem.visible = true;
        monthlyFrequencyOnWeekDayItem.visible = true;
      }
      form.option("items", items);
    }

    function updateFormItemsVisibility(selectedOption: {
      id: number;
      name: string;
      config?: {
        type?: string;
        step?: number;
        days?: boolean;
        months?: boolean;
      };
    }): void {
      const form = e.form; // Assuming e.form is globally available or passed as an argument

      const items = form.option("items") as Array<any>;

      // Find the relevant form items by their dataField property
      const repeatOnDayItem = items.find(
        (item) => item.dataField === "repeatOnDay"
      );
      const repeatOnDaysItem = items.find(
        (item) => item.dataField === "repeatOnDays"
      );
      const repeatOnMonthsItem = items.find(
        (item) => item.dataField === "repeatOnMonths"
      );
      const repeatOnWeekdayOnMonths = items.find(
        (item) => item.dataField === "repeatOnWeekdayOnMonths"
      );

      if (repeatOnDayItem) repeatOnDayItem.visible = false;
      if (repeatOnDaysItem) repeatOnDaysItem.visible = false;
      if (repeatOnMonthsItem) {
        repeatOnMonthsItem.visible = false;
        repeatOnWeekdayOnMonths.visible = false;
      }

      // Update visibility based on the selectedOption ID
      if (selectedOption.id === 2 && repeatOnDayItem) {
        repeatOnDayItem.visible = true;
      } else if (selectedOption.id === 3 && repeatOnDaysItem) {
        repeatOnDaysItem.visible = true;
      } else if (selectedOption.id === 4 && repeatOnMonthsItem) {
        repeatOnMonthsItem.visible = true;
        repeatOnWeekdayOnMonths.visible = true;
      }

      // Apply the updated configuration back to the form
      form.option("items", items);
    }

    function updateDateFieldsVisibility(isVisible: boolean) {
      const form = e.form;
      const items = form.option("items") as any[];

      const dateItem = items.find((item) => item.dataField === "onceDate");

      const startDateItem = items.find(
        (item) => item.dataField === "startDate"
      );
      const endDateItem = items.find((item) => item.dataField === "endDate");
      const repeatDropdownItem = items.find(
        (item) => item.dataField === "repeatType"
      );
      const repeatOnDayItem = items.find(
        (item) => item.dataField === "repeatOnDay"
      );
      const repeatOnDaysItem = items.find(
        (item) => item.dataField === "repeatOnDays"
      );
      const repeatOnMonthsItem = items.find(
        (item) => item.dataField === "repeatOnMonths"
      );

      if (dateItem) {
        dateItem.visible = !isVisible;
      }

      if (startDateItem && endDateItem) {
        startDateItem.visible = isVisible;
        endDateItem.visible = isVisible;
      }

      if (repeatDropdownItem) {
        repeatDropdownItem.visible = isVisible;
      }

      if (repeatOnDayItem) {
        repeatOnDayItem.visible = isVisible && repeatOnDayItem.visible;
      }
      if (repeatOnDaysItem) {
        repeatOnDaysItem.visible = isVisible && repeatOnDaysItem.visible;
      }
      if (repeatOnMonthsItem) {
        repeatOnMonthsItem.visible = isVisible && repeatOnMonthsItem.visible;
      }
      form.option("items", items);
    }
  };

  async function onAppointmentAdded(e: AppointmentAddedEvent): Promise<void> {
    const checklistId = formState.idChecklist;

    const scheduleRequest = {
      description: "New Schedule",
      type: formState.repeatType,
      startDate: formState.startDate || null,
      endDate: formState.endDate || null,
      onceDate: formState.onceDate || new Date(),
      dailyFrequency: formState.repeatOnDay || 1,
      weeklyFrequency: formState.repeatOnDays || 0, // Convert weekday names to bitmask, // Monday and Saturday
      monthlyFrequencyMonth: formState.repeatOnMonths || 0, // January and December
      monthlyFrequencyDay: formState.monthlyFrequencyDay || null,
      monthlyFrequencyOn: formState.monthlyOnWeekDays || null,
      monthlyFrequencyOnDay: formState.monthlyFrequencyOnWeekDay || null,
    };

    const apiUrl = `${BACKENDURL}/api/checklists/${checklistId}/schedule`;

    try {
    const response = await fetch(apiUrl, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(scheduleRequest),
    });
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    const updatedAppointments = await fetchData();
    setSchedulerData(updatedAppointments);
    setToastMessage(t("Checklist scheduled successfully!"));
    setToastType('success');
    setToastVisible(true);
  } catch (error) {
    console.error(t("Error scheduling checklist:"), error);

    setToastMessage(t("Error scheduling checklist. Please try again."));
    setToastType('error');
    setToastVisible(true);
    }
  }

  const [schedulerData, setSchedulerData] = useState([]);

  const fetchData = async () => {
    const url = `${BACKENDURL}/api/checklists?skip=0&take=100&fetchSchedules=true`;
    const response = await fetch(url, {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
    });
    if (!response.ok) {
      throw new Error("Failed to fetch data");
    }
    const data = await response.json();

    const appointments = data
  .map((item: {
    idChecklistSchedule: number;
    schedules: any[];
    name: string;
    description: any;
  }) =>
    item.schedules.flatMap((schedule: {
      idChecklistSchedule: number;
      description: string;
      startDate: string | number | Date;
      endDate: string | number | Date;
      onceDate: string | number | Date;
      type: number;
      dailyFrequency?: number;
      weeklyFrequency?: number;
      monthlyFrequencyMonth?: number;
      monthlyFrequencyDay?: number;
      monthlyFrequencyOn?: number;
      monthlyFrequencyOnDay?: number;
    }) => {
      let recurrenceRule = "";
      let startDate = new Date(schedule.startDate);

      // Handling recurrence based on schedule type
      switch (schedule.type) {
        case 1: // Once
          if (schedule.onceDate) {
            startDate = new Date(schedule.onceDate);
          }
          break;

        case 2: // Daily frequency
          recurrenceRule = "FREQ=DAILY";
          const includedDays = findIncludedWeekDays(schedule.dailyFrequency ?? 0).toString();
          if (includedDays) {
            recurrenceRule += ";BYDAY=" + includedDays;
          }
          if (schedule.endDate) {
            recurrenceRule += ";UNTIL=" + formatDate(new Date(schedule.endDate));
          }
          break;

        case 3: // Weekly frequency
          recurrenceRule = "FREQ=WEEKLY";
          const includedWeekDays = findWeeklyIncludedWeekDays(schedule.weeklyFrequency ?? 0).toString();
          if (includedWeekDays) {
            recurrenceRule += ";BYDAY=" + includedWeekDays;
          }
          if (schedule.endDate) {
            recurrenceRule += ";UNTIL=" + formatDate(new Date(schedule.endDate));
          }
          break;

        case 4: // Monthly frequency
          recurrenceRule = "FREQ=MONTHLY";
          const includedMonths = findIncludedMonths(schedule.monthlyFrequencyMonth ?? 0).toString();
          if (includedMonths) {
            recurrenceRule += ";BYMONTH=" + includedMonths;
          }

          if (schedule.monthlyFrequencyDay) {
            const includedDates = findIncludedDates(schedule.monthlyFrequencyDay ?? 0).toString();
            if (includedDates) {
              recurrenceRule += ";BYMONTHDAY=" + includedDates;
            }
          } else if (schedule.monthlyFrequencyOn && schedule.monthlyFrequencyOnDay) {
            const includedWeekDays = findIncludedWeekDays(schedule.monthlyFrequencyOnDay ?? 0);
            const includedWeekNumbers = getMonthlyPosition(schedule.monthlyFrequencyOn ?? 0).toString();

            // Generate separate schedules for each day
            return includedWeekDays.map((day) => {
              const dayRecurrenceRule = `FREQ=MONTHLY;BYDAY=${day};BYSETPOS=${includedWeekNumbers}`;
              return {
                id: schedule.idChecklistSchedule,
                text: `${item.name}`,
                startDate: new Date(startDate),
                endDate: new Date(new Date(startDate).setHours(13, 59, 59)),
                description: item.description,
                recurrenceRule: dayRecurrenceRule,
                allDay: !schedule.startDate || !schedule.endDate,
              };
            });
          }
          break;
      }

      return {
        id: schedule.idChecklistSchedule,
        text: `${item.name}`,
        startDate: new Date(startDate),
        endDate: new Date(new Date(startDate).setHours(13, 59, 59)),
        description: item.description,
        recurrenceRule: recurrenceRule,
        allDay: !schedule.startDate || !schedule.endDate,
      };
    })
  )
  .flat(); // Flatten the nested arrays of schedules

setSchedulerData(appointments);
return appointments;
  };

  function getMonthlyPosition(position: number): string {
    const positionMap: { [key: number]: string } = {
      [MonthlyFrequencyOn.First]: "1",
      [MonthlyFrequencyOn.Second]: "2",
      [MonthlyFrequencyOn.Third]: "3",
      [MonthlyFrequencyOn.Fourth]: "4",
      [MonthlyFrequencyOn.Last]: "-1",
    };

    const includedPositions: string[] = [];

    for (const key in positionMap) {
      if (position & Number(key)) {
        includedPositions.push(positionMap[Number(key)]);
      }
    }

    return includedPositions.join(",");
  }

  const handleAppointmentDoubleClick = (e: AppointmentDblClickEvent) => {
    e.cancel = true;
  };

  useEffect(() => {
    fetchData().catch((error) => console.error(error));
  }, []);

  function formatDate(date: Date) {
    if (!date) return null;

    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const day = date.getDate();

    const formattedMonth = month < 10 ? `0${month}` : month.toString();
    const formattedDay = day < 10 ? `0${day}` : day.toString();

    return `${year}${formattedMonth}${formattedDay}`;
  }

  function findIncludedWeekDays(value: number): string[] {
    const includedDays: string[] = [];

    const dayAbbreviations: { [key: string]: string } = {
      Monday: "MO",
      Tuesday: "TU",
      Wednesday: "WE",
      Thursday: "TH",
      Friday: "FR",
      Saturday: "SA",
      Sunday: "SU",
    };

    for (const day in DailyFrequencyDays) {
      if (isNaN(Number(day))) {
        const dayValue = DailyFrequencyDays[day as keyof typeof DailyFrequencyDays];

        if ((value & dayValue) !== 0) {
          const abbreviation = dayAbbreviations[day];
          if (abbreviation) {
            includedDays.push(abbreviation);
          }
        }
      }
    }

    return includedDays;
  }
function findWeeklyIncludedWeekDays(value: number): string[] {
    const includedDays: string[] = [];

    const dayAbbreviations: { [key: string]: string } = {
      Monday: "MO",
      Tuesday: "TU",
      Wednesday: "WE",
      Thursday: "TH",
      Friday: "FR",
      Saturday: "SA",
      Sunday: "SU",
    };

    for (const day in WeeklyFrequencyDays) {
      if (isNaN(Number(day))) {
        const dayValue = WeeklyFrequencyDays[day as keyof typeof WeeklyFrequencyDays];

        if ((value & dayValue) !== 0) {
          const abbreviation = dayAbbreviations[day];
          if (abbreviation) {
            includedDays.push(abbreviation);
          }
        }
      }
    }

    return includedDays;
  }
  function findIncludedMonths(value: number): number[] {
    const includedMonths: number[] = [];

    for (let month in MonthlyFrequencyMonths) {
      const monthValue: number =
      MonthlyFrequencyMonths[month as keyof typeof MonthlyFrequencyMonths];
      if ((value & monthValue) !== 0) {
        const monthIndex = Math.log2(monthValue) + 1;
        includedMonths.push(monthIndex);
      }
    }
    return includedMonths;
  }

  function findIncludedDates(value: number): number[] {
    const includedDays: number[] = [];

    for (let day in MonthlyFrequencyDays ) {
      const dayValue: number = MonthlyFrequencyDays[day as keyof typeof MonthlyFrequencyDays ];

      if (day !== "Last" && (value & dayValue) !== 0) {
        const dayIndex = Math.log2(dayValue) + 1;
        includedDays.push(dayIndex);
      }
    }

    if ((value & MonthlyFrequencyDays.Last) !== 0) {
      includedDays.push(32);
    }

    return includedDays.sort((a, b) => a - b);
  }
  const handleDelete = async (appointmentId: number): Promise<void> => {
    try {
      const url = `${BACKENDURL}/api/checklists/${appointmentId}/schedule`;
      const response = await fetch(url, {
        method: "DELETE",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
      });
      if (!response.ok) {
        const errorText = await response.text();
        throw new Error(
          `Failed to delete the appointment: ${response.status} - ${errorText}`
        );
      }
      setToastMessage(t("Checklist schedule deleted successfully!"));
      setToastType('success');
      setToastVisible(true);
      fetchData();
    } catch (error) {
      console.error("Error deleting appointment:", error);
      alert(t("Checklist schedule deleted successfully!"));
    }
  };
  const onAddScheduleClick = () => {
    if (schedulerRef.current) {
      const scheduler = schedulerRef.current.instance;

        scheduler.showAppointmentPopup(
          {
            startDate: new Date(),
            endDate: new Date(new Date().setHours(new Date().getHours() + 1)),
          },
          true
        );
    }
  };

  function getSchedulerHeight(): number {
    return window.innerHeight - occupiedHeight
  }

  return (
    <React.Fragment>
      <div className="button-container">
        <Button
          className={addButtonClassName}
          text={t("Schedule Checklists")}
          onClick={onAddScheduleClick}
          stylingMode="contained"
          type="default"/>
        </div>
      <Scheduler
        className={schedulerClassName}
        height={getSchedulerHeight()}
        ref={schedulerRef}
        firstDayOfWeek={1}
        views={views}
        dataSource={schedulerData}
        defaultCurrentView="month"
        startDayHour={8}
        defaultCurrentDate={currentDate}
        onAppointmentDblClick={handleAppointmentDoubleClick}
        appointmentTooltipRender={(props) => (
          <CustomTooltipRender
            {...props}
            handleDelete={handleDelete}
          />
        )}
        onAppointmentDeleted={fetchData}
        appointmentComponent={Appointment}
        onAppointmentAdded={onAppointmentAdded}
        onAppointmentFormOpening={onAppointmentFormOpening}
      ></Scheduler>
      <Toast
        visible={toastVisible}
        message={toastMessage}
        type={toastType}
        displayTime={2000}
        onHiding={() => setToastVisible(false)}
        position="top right"
      />
    </React.Fragment>
  );
}
const repeatOptions = [
  {
    id: 2,
    name: "Daily",
    config: { type: "weeks", step: 1, days: true },
  },
  {
    id: 3,
    name: "Weekly",
    config: { type: "weeks", step: 1, days: true },
  },
  {
    id: 4,
    name: "Monthly",
    config: { type: "month", step: 1, days: true },
  },
];

