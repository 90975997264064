import HttpClient from "./httpClient";
import { UserProfile } from "./types/userProfile";
const baseUrl = '/api/user';

export default class ProfileApi {

    static async getUserProfile():Promise<UserProfile> {
        var response = await HttpClient.get(baseUrl);
        return await response.json();
    }
}