import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { t } from "i18next";
import "./qualitycheckdetailpage.css";
import QualityChecksApi from "../../api/qualityChecksApi";
import { QualityCheckDetails } from "../../api/types/qualityCheckDetails";
import { LoadPanel } from "devextreme-react";

export default function QualityCheckDetailPage() {
  const [details, setDetails] = useState<QualityCheckDetails | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const { id } = useParams();

  useEffect(() => {

    async function fetchData() {
      setLoading(true);
       try {
          var data = await QualityChecksApi.getQualityCheck(id);
          setDetails(data);
        }
       catch (error) {
        console.error("Failed to fetch quality check details:", error);
      }
      finally {
        setLoading(false);
      }
    }

    if (id) fetchData();
  }, [id]);

  if (!details) {
    return <LoadPanel visible={loading} />
  }

  return (
    <div className="quality-check-details">
      <h3>
        {t("Quality Check Details:")} {details.idQualityCheck}
      </h3>
      <div>
        {details.state === 0 && (
          <>
            <h2>{t("Scheduled")}</h2>
          </>
        )}
        {details.state === 1 && (
          <>
            <h2>{t("Active")}</h2>
          </>
        )}
        {details.state === 2 && (
          <>
            <h2>{t("Finished")}</h2>
          </>
        )}
        {details.state === 3 && (
          <>
            <h2>{t("Expired")}</h2>
          </>
        )}
      </div>
      <div className="section">
        <h2>{t("Schedule & Inspector")}</h2>
        <p>
          {t("Scheduled On:")}{" "}
          {new Date(details.scheduledOn).toLocaleDateString()}
        </p>
        <p>
          {t("Inspector Name:")} {details.inspectorName || t("Not Assigned")}
        </p>
      </div>
      <div className="section">
        <h2>{t("Check Timing")}</h2>
        <p>
          {t("Check Started:")}{" "}
          {details.checkStarted
            ? new Date(details.checkStarted).toLocaleTimeString()
            : t("Not Started")}
        </p>
        <p>
          {t("Check Ended:")}{" "}
          {details.checkEnded
            ? new Date(details.checkEnded).toLocaleTimeString()
            : t("Ongoing")}
        </p>
      </div>
      <div className="section">
        <h2>{t("Checklist Details")}</h2>
        <p>
          {t("Name:")} {details.checklist.name}
        </p>
        <p>
          {t("Description:")} {details.checklist.description}
        </p>
        <p>
          {t("Revision Number:")} {details.checklist.revisionNumber}
        </p>
        <p>
          {t("Revision Date:")}{" "}
          {new Date(details.checklist.revisionDate).toLocaleDateString()}
        </p>
      </div>

      {details &&
        Array.isArray(details.answers) &&
        details.answers.length === 0 && (
          <div className="section">
            <h2>{t("Questions")}</h2>
            {details.checklist.questions.map((question, index) => (
              <div key={index}>
                <p>
                  {index + 1}. {question.description}
                </p>
              </div>
            ))}
          </div>
        )}

      {details &&
        Array.isArray(details.answers) &&
        details.answers.length > 0 && (
          <div className="section">
            <h2>{t("Questions and Answers")}</h2>
            {details.answers.map((answer, index) => (
              <div key={index} className="question">
                <p className="answer-description">
                  {answer.question.description}
                </p>
                <p className="answer-value">
                  {t("Answer:")}
                  <span>
                    {answer.answerBoolean !== null
                      ? answer.answerBoolean
                        ? t("Yes")
                        : t("No")
                      : answer.answerNumber !== null
                      ? answer.answerNumber
                      : answer.answerText !== null
                      ? answer.answerText
                      : t("No answer provided")  }
                  </span>
                </p>
              </div>
            ))}
          </div>
        )}
    </div>
  );
}
