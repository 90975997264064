import { Column } from "devextreme-react/data-grid";
import { t } from "i18next";
import ChecklistsApi from "../../api/checklistsApi";
import { useNavigate } from "react-router-dom";
import { GenericDataGrid } from "../../components/common/GenericDataGrid";
import { PagedList } from "../../api/types/pagedList";

export default function Checklists() {
  const navigate = useNavigate();

  const addChecklist = () => {
    navigate(`/checklists/new`);
  };

  const handleEdit = (id: string) => {
    navigate(`/checklists/${id}`);
  };

  const fetchData = async (page: number, take: number): Promise<PagedList<object>> => {
    return await ChecklistsApi.getChecklists(false, page, take)
  }

  return (
    <GenericDataGrid
      rowKey={"idChecklist"}
      fetchData={fetchData}
      handleAddNew={addChecklist}
      handleEdit={handleEdit}
      occupiedHeight={180}>
        <Column dataField={"idChecklist"} caption={"ID"} allowFiltering={false} width={"70px"} />
        <Column dataField={"code"} caption={t("Code")} allowHiding={false} minWidth={200} />
        <Column dataField={"name"} caption={t("Name")} allowHiding={false} minWidth={200} />
        <Column dataField={"description"} caption={t("Description")} minWidth={300} />
        <Column dataField={"revisionNumber"} caption={t("Revision")} width={"100px"} />
        <Column
          dataField={"revisionDate"}
          caption={t("Revision Date")}
          dataType={"date"}
          format={"HH:mm dd.MM.yyyy"}
          width={"200px"}
        />
      </GenericDataGrid>
  );
}
