import { Link, useLocation } from "react-router-dom";
import routes from "./app-routes";

const Breadcrumb = () => {
  const location = useLocation();
  const pathnames = location.pathname.split("/").filter((x) => x);

  const breadcrumbItems = pathnames.map((_, index) => {
    const path = `/${pathnames.slice(0, index + 1).join("/")}`;
    const matchingRoute = routes.find(
      (route: { path: string; }) => route.path === path || (route.path.includes(":") && path.startsWith(route.path.split(":")[0]))
    );

    return matchingRoute ? (
      <li key={path}>
        <Link to={path}>{matchingRoute.breadcrumb}</Link>
      </li>
    ) : null;
  });

  return (
    <div className="breadcrumbs">
      <nav >
        <ol>
          {breadcrumbItems}
        </ol>
      </nav>
    </div>
  );
};

export default Breadcrumb;
