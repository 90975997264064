import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Column, FilterRow, HeaderFilter } from "devextreme-react/data-grid";
import "./qualitycheckslist.css";
import { t } from "i18next";
import QualityChecksApi from "../../api/qualityChecksApi";
import { GenericDataGrid } from "../../components/common/GenericDataGrid";
import { PagedList } from "../../api/types/pagedList";
import { filterHelper } from "../../helpers/DataGridHelper";

export default function QualityChecksList() {
  const today = new Date().toISOString().split("T")[0];

  const getTwoWeeksAgoDate = (): string => {
    const today = new Date();
    const twoWeeksAgo = new Date(today.getTime() - 60 * 24 * 60 * 60 * 1000);
    return twoWeeksAgo.toISOString().split("T")[0];
  };

  const navigate = useNavigate();

  // TODO: set those dates as default values in the filter
  const [scheduledDateFrom, setScheduledDateFrom] = useState<string>(getTwoWeeksAgoDate());
  const [scheduledDateUntil, setScheduledDateUntil] = useState<string>(today);
  const [scheduledDateOperation, setScheduledDateOperation] = useState<string|null>("between")

  // TODO: set those states as default values in the filter
  const [states, setStates] = useState<{
    active: boolean,
    finished: boolean,
    expired: boolean,
    scheduled: boolean
  }>({
    active: true,
    finished: true,
    expired: true,
    scheduled: true
  })

  const handleRowClick = (id: string) => {
    navigate(`/qualitychecks/${id}`);
  };

  const columnIndexes = {
    "scheduledOn": 2,
    "state": 3
  }

  const fetchData = async (page: number, take: number): Promise<PagedList<object>> => {
    return await QualityChecksApi.getReports(
      scheduledDateFrom,
      scheduledDateUntil,
      states.active,
      states.finished,
      states.expired,
      states.scheduled,
      page,
      take
    );
  }

  const handleOptionsChanged = (e: any) => {
    handleScheduledOnFilterChanged(e)
    handleStateFilterChanged(e)
  }

  const handleScheduledOnFilterChanged = (e: any) => {
    let operation
    if (e.fullName === filterHelper.columnFilterOperation(columnIndexes["scheduledOn"])) {
        if (!e.value)
            return

        setScheduledDateOperation(e.value)
        operation = e.value
    }

    if (e.fullName === filterHelper.columnFilterValue(columnIndexes["scheduledOn"])) {
        if (!e.value) {
            setScheduledDateFrom(getTwoWeeksAgoDate())
            setScheduledDateUntil(today)
            return
        }
        if (operation === "between" || scheduledDateOperation === "between") {
            if (e.value[0]) {
                setScheduledDateFrom(e.value[0].toISOString())
            }
            if (e.value[1]) {
                setScheduledDateUntil(e.value[1].toISOString())
            }
        } else {
            if (e.value) {
                setScheduledDateFrom(e.value.toISOString())
            }
        }
    }
  }

  const handleStateFilterChanged = (e: any) => {
    if (e.fullName === filterHelper.columnFilterValues(columnIndexes["state"])) {
      const values: Array<number> = e.value
      if (!values) {
        setStates({
          active: true,
          finished: true,
          expired: true,
          scheduled: true
        })
        return
      }

      setStates({
        active: values.includes(1),
        finished: values.includes(2),
        expired: values.includes(3),
        scheduled: values.includes(0)
      })
    }
  }

  const stateFilterOptions = [
    { text: "Scheduled", value: 0 },
    { text: "Active", value: 1 },
    { text: "Finished", value: 2 },
    { text: "Expired", value: 3 },
  ]

  const stateLookupConfig = {
    store: {
        type: 'array',
        data: stateFilterOptions,
        key: 'id'
    },
  }

  const stateLookup = {
    dataSource: stateLookupConfig,
    valueExpr: "value",
    displayExpr: "text",
  }

  return (
    <>
      <GenericDataGrid
          rowKey={"idQualityCheck"}
          fetchData={fetchData}
          handleEdit={handleRowClick}
          onOptionChanged={handleOptionsChanged}
          occupiedHeight={170}>
            {/* TODO: should FilterRow be applied on click? */}
            <FilterRow visible applyFilter="onClick" />
            <HeaderFilter visible />
            <Column dataField="idQualityCheck" dataType="string" caption="ID" width={70} allowHeaderFiltering={false} allowFiltering={false} />
            <Column dataField="checklist.name" dataType="string" caption={t("Checklist Name")} width={300} allowHeaderFiltering={false} allowFiltering={false} />
            <Column
              dataField="scheduledOn"
              dataType="date"
              width={170}
              caption={t("Scheduled On")}
              selectedFilterOperation="between"
              filterValues={[scheduledDateFrom, scheduledDateUntil]}
              filterOperations={["between"]}
              allowHeaderFiltering={false} />
            <Column
                dataField="state"
                dataType="string"
                caption={t("State")}
                width={170}
                lookup={stateLookup}
                allowHeaderFiltering
                allowFiltering={false}>
                  <HeaderFilter allowSearch allowSelectAll dataSource={stateFilterOptions} />
                </Column>
            <Column dataField="inspectorName" dataType="string" caption={t("Inspector Name")} allowHeaderFiltering={false} allowFiltering={false} />
      </GenericDataGrid>
    </>
  );
}
