import { Button, DataGrid } from "devextreme-react";
import { Pager, Paging } from "devextreme-react/cjs/data-grid";
import CustomStore from "devextreme/data/custom_store";
import { t } from "i18next";
import React, { useState } from "react";
import { useRef } from "react";
import { PagedList } from "../../api/types/pagedList";
import "./GenericDataGrid.scss"

export interface GenericDataGridProps {
    rowKey: string
    handleAddNew?: () => void
    fetchData: (page: number, take: number) => Promise<PagedList<object>>
    handleEdit: (id: string) => void
    onOptionChanged?: any
    occupiedHeight?: number
    children: React.ReactNode
}

export const GenericDataGrid: React.FC<GenericDataGridProps> = (props) => {
  const dataGridRef = useRef<DataGrid>(null)
  const pageSize = 100
  const [currentPage, setCurrentPage] = useState(1)

  const store = new CustomStore({
    key: props.rowKey,
    useDefaultSearch: false,
    async load(loadOptions) {
      try {
        const response = await props.fetchData(currentPage, pageSize)
        return {
          data: response.items,
          totalCount: response.totalCount
        }
      } catch (error) {
      }
    }
  })

  return (
    <React.Fragment>
      {!!props.handleAddNew && <Button
        className="add-button"
        text="Add"
        onClick={props.handleAddNew}
        stylingMode="contained"
        type="default" />}
      <DataGrid
        className="data-grid"
        ref={dataGridRef}
        height={window.innerHeight - (props.occupiedHeight ?? 250)}
        dataSource={store}
        onRowClick={e => props.handleEdit(e.key)}
        columnAutoWidth
        columnHidingEnabled
        rowAlternationEnabled
        remoteOperations={{ filtering: true, paging: true, sorting: true }}
        onOptionChanged={props.onOptionChanged}>
        <Paging defaultPageSize={pageSize} enabled onPageIndexChange={e => {
            setCurrentPage(e + 1)
        }} />
        <Pager visible />
        {props.children}
      </DataGrid>
    </React.Fragment>
  );
}

