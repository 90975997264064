import { SchedulerTypes } from "devextreme-react/scheduler";

type AppointmentProps = {
  data: { targetedAppointmentData: SchedulerTypes.Appointment };
};

const Appointment = (props: AppointmentProps) => {
  const { targetedAppointmentData } = props.data;

  return (
    <div className="showtime-preview">
      <div>{targetedAppointmentData.text} </div>
    </div>
  );
};

export default Appointment;
