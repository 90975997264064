export const filterHelper = {
    columnFilterOperation(columnIndex: number): string {
        return `columns[${columnIndex}].selectedFilterOperation`
    },
    columnFilterValue(columnIndex: number): string {
        return `columns[${columnIndex}].filterValue`
    },
    columnFilterValues(columnIndex: number): string {
        return `columns[${columnIndex}].filterValues`
    }
}
