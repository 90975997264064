import defaultUser from "../utils/default-user";
import { BACKENDURL } from "./constants";

export async function signIn(username: string, password: string) {
  try {
    const response = await fetch(`${BACKENDURL}/api/login`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include", 
      body: JSON.stringify({ username, password }),
    });
    if (response.ok) {
      return {
        isOk: true,
        data: defaultUser,
      };
    } else {
      return {
        isOk: false,
        message: "Authentication failed",
      };
    }
  } catch (error) {
    console.error("Error during the login process:", error);
    return {
      isOk: false,
      message: "Network error or server is down",
    };
  }
}

export async function getUser() {
  try {
    return {
      isOk: true,
      data: defaultUser,
    };
  } catch {
    return {
      isOk: false,
    };
  }
}

export async function createAccount(email: string, password: string) {
  try {
    console.log(email, password);

    return {
      isOk: true,
    };
  } catch {
    return {
      isOk: false,
      message: "Failed to create account",
    };
  }
}

export async function changePassword(email: string, recoveryCode?: string) {
  try {
    console.log(email, recoveryCode);

    return {
      isOk: true,
    };
  } catch {
    return {
      isOk: false,
      message: "Failed to change password",
    };
  }
}

export async function resetPassword(email: string) {
  try {
    console.log(email);

    return {
      isOk: true,
    };
  } catch {
    return {
      isOk: false,
      message: "Failed to reset password",
    };
  }
}
